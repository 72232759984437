
import DomainPic from '../../../assets/revampHomeassets/DomainPic.svg';
import FinderImg from '../../../assets/revampHomeassets/finderImg.svg';
import VerificationImg from '../../../assets/revampHomeassets/verifierImg.svg';
import tool1 from '../../../assets/revampHomeassets/tool1.svg';
import tool2 from '../../../assets/revampHomeassets/tool2.svg';
import tool3 from '../../../assets/revampHomeassets/tool3.svg';
import tool4 from '../../../assets/revampHomeassets/tool4.svg';
import tool5 from '../../../assets/revampHomeassets/tool5.svg';
import tool6 from '../../../assets/revampHomeassets/tool6.svg';
import email from 'assets/gmailIcon.svg';
import building from '../../../assets/homeassets/building.png';
import coldemail from '../../../assets/homeassets/coldemail.jpg';
import digitalimg from '../../../assets/homeassets/digitalimg.jpg';
import domsearch from '../../../assets/homeassets/domsearch.png';
import efinder from '../../../assets/homeassets/efinder.jpg';
import everification from '../../../assets/homeassets/everification.png';
import salesmail from '../../../assets/homeassets/salesmail.jpg';

export const heroImgData = [
    {
        id: 1,
        pic: FinderImg,
    },
    {
        id: 1,
        pic: VerificationImg,
    },
    {
        id: 1,
        pic: DomainPic,
    },
]

export const featuresData =[
    {
        heading:'Email Verifier',
        caption:"Market-leading & user-friendly email verification with exceptional customer support.",
        path: "/email-verifier",
        icon: email
    },
    {
        heading:"Domain Verifier",
        caption:"Verify the Domain of company of your likings With Confidence.",
        path: "/domain-verifier",
        icon: email
    },
    {
        heading:"Email from name & Domain",
        caption:"Trying to find out a contact within a corporation??, then you are at the right Place.",
        path: "/email-from-name-and-domain",
        icon: email
    },
    {
        heading:"Search Company",
        caption:"A Powerful tool that provides valuable insights about companies of interest.",
        icon: email,
        path: "/search-company",
    },
    {
        heading:"Bulk tasks",
        caption:"For huge lists of emails to find or verify, Bulk verifier and finder are fast and efficient way to aid you.",
        icon: email,
        path: "/bulks",
    },
    {
        heading:"Campaigns",
        caption:"Run camppaigns with Discovemail's customised plans.",
        icon: email,
        path: "/campaign",
    },
    ]

    export const toolData = [
        {
            imgSrc: tool1 ,
            title: "Ensure email deliverability." ,
            details: "We can identify emails that bounce by using our email validation software. Keep your bounce rate below 5% by regularly cleaning your list." ,
        },
        {
            imgSrc: tool2 ,
            title: "MX Record Detection" ,
            details: "Our platform indicates the presence of an MX Record for checked domains, ensuring authenticity of email addresses." ,
        },
        {
            imgSrc: tool3  ,
            title: "Free Email Verifier" ,
            details: "Quickly check email addresses to determine if they're from free, toxic, or disposable domains. Plus, receive names when available. Use it anytime for swift verification." ,
        },
        {
            imgSrc: tool4 ,
            title: "Find the email address" ,
            details: "Find the verified email address of any professional" ,
        },
        {
            imgSrc: tool5  ,
            title: "Send a cold email campaign" ,
            details: "Connect your email for creating, personalising, scheduling, and sending targeted campaigns at scale with Discovemail." ,
        },
        {
            imgSrc: tool6 ,
            title: "Verify the email" ,
            details: "Verified email address of any professional" ,
        },
    ]


    export const blogData = [
        {
            imgUrl : building,
            heading : "Price modification(January-24)",
            dateTime : "February 13, 2024",
            category : "Uncategorized",
            text : "We're refining our pricing to better accommodate your needs and enhance flexibility.  For a quick view of the changes, you…",
            blogUrl : "https://blog.discovemail.com/price-modification-january-24/"
        },
        {
            imgUrl : domsearch,
            heading : "Domain Search: How to Find and Validate Your Perfect Domain",
            dateTime : "December 4, 2023",
            category : "Uncategorized",
            text : "Domain names are your virtual address on the internet, representing your brand, identity, and online presence. The right domain selection…",
            blogUrl : "https://blog.discovemail.com/domain-search/"
        },
        {
            imgUrl : efinder,
            heading : "Email Finder: Exploring Discovemail’s Powerful Feature.",
            dateTime : "November 27, 2023",
            category : "Email Finder",
            text : "Looking to find an email address but don't know where to start? Look no further. In this article, we'll dive…",
            blogUrl : "https://blog.discovemail.com/email-finder/"
        },
        {
            imgUrl : everification,
            heading : "Unlocking the Power of Email Verification.",
            dateTime : "November 20, 2023",
            category : "Email Verification",
            text : "Unlocking the power of email verification is the foundational key to enhancing deliverability and maximizing engagement. In today's fast-paced digital…",
            blogUrl : "https://blog.discovemail.com/email-verification/"
        },
        {
            imgUrl : digitalimg,
            heading : "What is email verification?",
            dateTime : "October 31, 2023",
            category : "Email Verification",
            text : "In today's growing world all communications happen digitally, and the power of email communication is growing fast and fast. With…",
            blogUrl : "https://blog.discovemail.com/what-is-email-verification/"
        },
        {
            imgUrl : salesmail,
            heading : "How to write an effective sales email.",
            dateTime : "October 26, 2023",
            category : "Sales email",
            text : "In today's digital age, where communication happens at the click of a button, the power of a well-crafted sales email…",
            blogUrl : "https://blog.discovemail.com/how-to-write-an-effective-sales-email/"
        },
        {
            imgUrl : coldemail,
            heading : "Discover the Top 10 Cold Email Software Solutions for 2023",
            dateTime : "September 22, 2023",
            category : "Email Software",
            text : "Are you seeking to enhance your email marketing strategy?” Look at this article for Email Software tools. Do you aspire to…",
            blogUrl : "https://blog.discovemail.com/discover-the-top-10-cold-email-software-solutions-for-2023/"
        },
    ]
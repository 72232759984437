import Elevate from 'home_revamp/Elevate'
import NewFooter from 'home_revamp/NewFooter'
import Navbar from 'new_home/Navbar_1'
import React from 'react'
import { blogData } from 'pages/dashboard/newUiDashboard/NewUiData'
import BlogCard from './BlogCard'
import grlPic from '../../../assets/homeassets/grlPic.svg'
import Homeravamp from '../../../home_revamp/Homeravamp.css'

const NewBlog = () => {
  return (
    <div className="" >
    <Navbar />
    <div className="blogInner heroTopPadding bgcover bgcenter bgNoRepeat px-5">
      <h1 className='position-absolute text-white w-75 fs78 fw900' >THE USE OF ARTIFICIAL INTELLIGENCE IN BUSINESSES</h1>
      <div className="d-flex imgDiv justify-content-center w-100 pt-5">
        <img src={grlPic} alt="image not found" className='pt-5' />
      </div>
    </div>
    <Elevate />
    <BlogCard blogData = {blogData} />
    <NewFooter />
    
    
    
    </div>
  )
}

export default NewBlog
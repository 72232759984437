import React from 'react'
import { FaRegClock } from "react-icons/fa6";
import { CiFolderOn } from "react-icons/ci";

const BlogCard = (props) => {
    
  return (
    <div className='bgNoRepeat bgcover blogPage px-3 py-5'>
        <div className="row px-5">
        {
          props?.blogData.map((elem, index)=>(
            <div className='col-md-4 p-3 my-3  ' key={index}>
            <div className="blogCardInner h-100 bg-white rounded-2 shadow-sm p-3">
              <img src={elem.imgUrl} alt="image not found" className='w-100 blogimg' />
            <div className="px-3">
            <h3 className='text-break'>{elem.heading}</h3>
            <div className="timedate">
              <span> <FaRegClock style={{color: "grey"}} /> {elem.dateTime}</span>
              <span className='px-2' > <CiFolderOn /> {elem.category}</span>
            </div>
            <p>{elem.text}</p>
            <a className='text-secondary' href={elem.blogUrl}>Continue Reading </a>
            </div>
            </div>
            
            
            </div>
            
          ))
        }
        </div>
    </div>
  )
}

export default BlogCard
